.ticket-forum {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 800px;
  height: 100vh;
  position: fixed;
  background: $white;
  z-index: 1000;
  top: 0;
  right: -800px;
  transition: right 0.5s ease-in-out;

  &.show {
    right: 0;
  }

  &-title {
    height: 100px;
  }

  &-body {
    height: calc(100vh - 100px);
    overflow: auto;
  }

  .badge-open-ticket {
    color: $danger;
    border: 1px solid $danger;
    background-color: $danger-active;
  }

  .badge-resolved-ticket {
    color: $teal;
    border: 1px solid $teal;
    background-color: $teal-active;
  }
}

.ticket-forum-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none;
}

.ticket-forum.show ~ .ticket-forum-overlay {
  display: block;
}

@include media-breakpoint-down(md) {
  .ticket-forum {
    width: 100vw;
    right: -100vw;
  }
}
