@import 'variables.custom';
@import '../layout/variables';

.bg-page {
  background-color: $page-bg !important;
}

.vh-75 {
  max-height: 75vh !important;
}

.transition-none {
  transition: none !important;
}

.text-pre-wrap {
  white-space: pre-wrap !important;
}

.outline-none {
  outline: none !important;
}

.bg-image-gori {
  background-image: $gori-gradient !important;
}

.text-gori {
  background: $gori-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.table-tabs {
  &__tab {
    display: inline-flex;
    background-color: $white;
    font-weight: 600;
    font-size: 10px;
    color: gray;
    border: 2px solid $light;
    padding: 10px;
    border-radius: 15px;
    margin: 6px 6px 6px 0;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 2px;

    @media (min-width: 686px) {
      padding: 15px 20px;
      border-radius: 15px 15px 0 0;
      margin: 0;
      font-size: 14px;
    }

    &:hover,
    &.active {
      border-bottom: 2px solid $white;
      color: $primary;
      background: $white;
      box-shadow: rgba(0, 0, 0, 0.16) 0 2px 8px;
      font-size: 11px;

      @media (min-width: 686px) {
        font-size: 14px;
      }
    }

    &__count {
      margin-left: 4px;
      padding: 0 8px;
      background-color: $light;
      border-radius: 4px;

      &.active {
        color: $white;
        background-color: $primary;
      }
    }

    &:hover > &__count {
      margin-left: 4px;
      padding: 0 8px;
      background-color: $primary;
      border-radius: 4px;
      color: $white;
    }
  }

  &.table-tabs {
    &.claims-tabs {
      .table-tabs__tab {
        @media (min-width: 686px) {
          font-size: 10px;
          padding: 10px;
          border-radius: 15px;
          margin: 6px 6px 6px 0;
        }

        @media (min-width: 880px) {
          font-size: 14px;
          padding: 15px 20px;
          border-radius: 15px 15px 0 0;
          margin: 0;
        }

        &:hover,
        &.active {
          @media (min-width: 686px) {
            font-size: 11px;
          }

          @media (min-width: 880px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.dropdown {
  min-width: 180px;

  &__label {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border: 1px solid $gray-300;
    border-radius: 10px;
    background-color: $white;
    white-space: nowrap;
    cursor: pointer;

    &__left {
      display: flex;
      align-items: center;
    }

    &__value {
      max-width: 150px;
      border-left: 1px solid #d1d1d1;
      padding-left: 10px;
      margin-left: 10px;

      &--input {
        max-width: 100px;
        outline: none;
        border: none;
        padding: 6px 8px;
        background: #f5f8fa;

        &:hover {
          background: #fff;
        }
      }
    }

    &__arrow {
      &:hover {
        color: hsl(0, 0%, 60%);
      }
    }
  }

  &__menu {
    background: white;
    position: absolute;
    right: 0;
    padding: 0;
    margin-top: 4px;
    border: 1px solid #d8dbdf;
    max-height: 380px;
    overflow-y: auto;
    max-width: 250px;

    .dropdown-submenu {
      &:first-child {
        border-radius: 0.65rem 0.65rem 0;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 2px 0;
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
          color: $primary;
          background: $primary-active;
        }

        &.show {
          font-weight: 600;
          background-color: $light;
        }
      }

      .item-custom {
        color: black;
      }

      &__inner {
        display: none;

        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 2px 0;
          padding: 8px 26px;
          cursor: pointer;

          &:hover {
            color: $primary;
            background: $primary-active;
          }
        }

        &.show {
          display: block;
        }

        .item-custom {
          color: black;

          &:hover {
            color: black;
          }
        }
      }
    }
  }
}

.balance-button {
  padding: 5px !important;
  transition: all;
  border-radius: 12px;
  border-color: $primary;
  background-color: #ffffff;
  color: #5e6278;

  @media (min-width: 576px) {
    padding: 0 5px !important;
    border-radius: 50rem !important;
  }

  .balance-company {
    color: $gray-700;
    background: #e4efee;
    padding: 5px 10px;
    border-radius: 13px;
    margin: 2px 5px 2px 0;
    font-size: 14px;
    font-weight: normal;
  }

  .svg-icon.svg-icon-2 svg {
    margin-bottom: 2.5px;
  }

  &:hover {
    background-color: $primary;
    color: $white;

    .balance-company {
      background-color: $primary;
      color: $white;
    }

    .svg-icon.svg-icon-2 svg {
      color: $white;
    }
  }
}

.map-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #181c32;
  text-align: inherit;
  white-space: break-spaces;
  background-color: transparent;
  border: 0;

  &:hover {
    background-color: #ddd;
  }
}

.select-customs {
  position: relative;

  .select-customs {
    position: relative;

    &__icon-delete {
      position: absolute;
      top: 25%;
      right: 10%;
      padding: 0 4px;
    }
  }
}

.bg-input-dark {
  .dropdown__label {
    background-color: $bg-color-label;

    &__value--input {
      background-color: $bg-color-value-input;
    }
  }
}

.input-custom {
  position: relative;
  font-size: 1.25rem;

  .input-custom__input.is-invalid + .input-custom__append--right,
  .input-custom__input.is-valid + .input-custom__append--right {
    right: 40px;
  }

  &__append {
    color: $gray-600;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--right {
      right: 10px;
    }

    &--left {
      left: 10px;
    }
  }
}

.fixed-column {
  @media (min-width: 768px) {
    z-index: 0 !important;
    position: sticky;
    right: 0;
    background-color: $white !important;
  }
}

.fixed-action {
  @media (min-width: 768px) {
    z-index: 0 !important;
    position: sticky;
    background-color: $white !important;
  }
}

.dropdown-menu.show {
  @at-root .fixed-column:has(.dropdown-menu.show) {
    z-index: 1 !important;
  }
}

.input-group.error-date {
  fieldset {
    border-color: $danger !important;
  }
}

.settings-container {
  height: 100%;

  @media (min-width: 1400px) {
    padding-left: 170px;
  }
}

.footer.settings-padding {
  @media (min-width: 1400px) {
    padding-left: 170px;
  }
}

#gori_aside.solid {
  + .aside--sub {
    @media (min-width: 1400px) {
      background-color: rgba(246, 248, 252, 1) !important;
    }
  }
}

#gori_aside_footer {
  .mobile-only {
    @media (min-width: 1400px) {
      display: none !important;
    }
  }

  .desktop-only {
    display: none !important;

    @media (min-width: 1400px) {
      display: block !important;
    }
  }
}

ol.paren-list-num {
  list-style-type: none;
  counter-reset: list;

  li {
    counter-increment: list;
    position: relative;
    padding-left: 2.5em;

    &:before {
      content: '( ' counter(list) ' )';
      position: absolute;
      left: 0;
    }
  }
}

ol.paren-list-alpha {
  list-style-type: none;
  counter-reset: list;

  li {
    counter-increment: list;
    position: relative;
    padding-left: 2.5em;

    &:before {
      content: '( ' counter(list, lower-alpha) ' )';
      position: absolute;
      left: 0;
    }
  }
}

// BEGIN: customs right-n30, ..., right-0, ..., right-30
$sizes: ();
@for $i from -30 through 30 {
  $sizes: append($sizes, if($i < 0, 'n#{abs($i)}', $i));
}

@each $index in $sizes {
  $value: if(
    type-of($index) == 'string',
    calc(-1 * #{str-slice($index, 2)} * 0.25rem),
    $index * 0.25rem
  );

  .right-#{$index} {
    right: $value !important;
  }
}
// END: customs right-n30, ..., right-0, ..., right-30

// BEGIN: customs text-truncate-1, ..., text-truncate-10
@mixin text-truncate($lines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@for $i from 1 through 10 {
  .text-truncate-#{$i} {
    @include text-truncate($i);
  }
}
// END: customs text-truncate-1, ..., text-truncate-10
