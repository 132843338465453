//
// Aside
//

.aside {
  &--main {
    height: 100vh;
    display: none !important;
    flex-direction: column;
    background-color: get($aside-config, bg-color);
    padding: 0;
    font-weight: 300;
    position: fixed;
    z-index: get($aside-config, z-index);
    overflow: hidden;

    &__center {
      height: auto;
      overflow-y: auto;
      overflow-x: hidden;

      &__item {
        cursor: pointer;
        &.active {
          border-radius: 8px;
          background: $primary-bold;
        }
      }
    }

    &__footer {
      position: fixed;
      bottom: 0;
      background: $primary;

      &__item {
        cursor: pointer;
        &.active {
          border-radius: 8px;
          background: $primary-bold;
        }
      }
    }
  }

  // Tablet & mobile modes < 1400px
  @include media-breakpoint-down(xxl) {
    &--main {
      width: get($aside-config, tablet-mobile-width);
      &__center {
        .accordion.accordion-icon-toggle {
          padding: 0 1rem;
          @media (min-width: 576px) {
            padding: 0 2.25rem;
          }
        }
        &__item {
          &__title,
          &__icon {
            color: $white;
          }
          & .accordion-icon {
            color: $white;
          }
          .accordion-body {
            padding: 0;
          }
        }
      }
      &__footer {
        width: 170px;
        height: 150px;

        @media (min-width: 576px) {
          width: 220px;
        }

        &__item {
          &__icon {
            color: $white;
          }
        }
      }
    }
    &--sub {
      display: none;
    }
  }

  // Laptop & Desktop mode > 1400px
  @include media-breakpoint-up(xxl) {
    &--main {
      display: flex !important;
      width: get($aside-config, laptop-desktop-width);
      &__center {
        .accordion.accordion-icon-toggle {
          padding: 0 1rem;
        }
        &__item {
          &__title,
          & .collapse,
          & .collapsing,
          & .collapsed {
            display: none;
            transition: none;
            transform: none;
          }
          &__icon {
            color: $white;
          }
          & .accordion-header:not(.collapsed) > &__icon {
            color: $primary-bold;
          }
          &.active .accordion-header:not(.collapsed) > &__icon {
            color: $white;
          }
          & .accordion-icon {
            color: $white;
            display: none !important;
          }
          &:not(.active) &__icon:hover {
            color: $primary-bold !important;
          }
        }
      }
      &__footer {
        height: fit-content;
        width: get($aside-config, laptop-desktop-width);

        &__item {
          &__icon {
            color: $white;
          }
          &:not(.active) &__icon:hover {
            color: $primary-bold !important;
          }
        }
      }
    }
    &--sub {
      display: none;
      width: get($aside-config, laptop-desktop-submenu-width);
      margin-left: get($aside-config, laptop-desktop-width);
      background-color: get($aside-config, bg-color-submenu);
      height: 100vh;
      flex-direction: column;
      padding: 0;
      font-weight: 300;
      position: fixed;
      z-index: get($aside-config, z-index);
      overflow: hidden;
      backdrop-filter: blur(2px);
      border-radius: 0 8px 8px 0;

      &.show {
        display: flex;
      }

      &__center {
        height: auto;

        &__item {
          cursor: pointer;

          &__icon {
            display: none;
            &.child {
              display: flex;
              color: $gray-700;
            }
          }
          &__title {
            height: 48px;
            margin-left: 16px;
            display: flex;
            align-items: center;
            color: $gray-700;
            font-weight: 600;

            &.child {
              margin-left: 0.5rem;
              height: 32px;
            }
          }

          & .accordion-icon {
            color: $gray-700;
          }

          .accordion-body {
            padding: 0;
          }

          .accordion-header:not(.collapsed),
          .accordion-header:hover {
            background-color: $white;
            & > span {
              color: $gray-900;
            }
          }

          .accordion-body .accordion-header:not(.collapsed) {
            background-color: get($aside-config, bg-color-submenu);
            & > span {
              color: $gray-700;
            }
          }
          .accordion-body &:not(.active) .accordion-header:hover {
            background-color: $white;
            & > span {
              color: $gray-900;
            }
          }

          &.active .accordion-header:not(.collapsed) {
            &:not(.child) {
              // background-color: $primary-active;
              background-color: unset;
            }
            & .accordion-icon,
            & span {
              color: $primary-bold;
            }
          }
        }
      }
    }
    &--sub__settings {
      transform: none !important;
      display: none;
      inset: auto auto 0px 0px;
      width: get($aside-config, laptop-desktop-submenu-width);
      margin-left: get($aside-config, laptop-desktop-width) !important;
      background-color: get($aside-config, bg-color-submenu);
      height: 100vh;
      flex-direction: column;
      padding: 0;
      font-weight: 300;
      position: fixed;
      z-index: get($aside-config, z-index);
      overflow: hidden;
      backdrop-filter: blur(2px);
      border-radius: 8px 8px 0;

      &.show {
        display: flex;
      }
    }
  }
}
